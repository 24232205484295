import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase configuration (from Firebase Console)
const firebaseConfig = {
  apiKey: "AIzaSyAAG1eU9x6biEm1Db8dBwr-XAT7Y5aev04",
  authDomain: "letterclub-d4a4b.firebaseapp.com",
  projectId: "letterclub-d4a4b",
  storageBucket: "letterclub-d4a4b.firebasestorage.app",
  messagingSenderId: "917032029106",
  appId: "1:917032029106:web:77aa6919fd99d089ee72ab",
  measurementId: "G-4Y0F1RCS8C"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export default db;
