import React, { useState, useEffect, useMemo } from "react";
import db from "./firebase-config";
import { doc, getDoc } from "firebase/firestore";
import "./styles.css";

const generateRandomColors = (count) => {
  return [...Array(count)].map(
    () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
  );
};

const Game = () => {
  const [gameData, setGameData] = useState(null);
  const [gradientColors, setGradientColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [revealedWord, setRevealedWord] = useState([]);
  const [points, setPoints] = useState(0);
  const [score, setScore] = useState(0);
  const [guessedLetters, setGuessedLetters] = useState({});
  const [message, setMessage] = useState("");

  const numberOfBlobs = 20; // Number of blobs to display

  // Fetch game data on initial load
  useEffect(() => {
    const fetchGameData = async () => {
      const today = new Date().toISOString().split("T")[0];
      try {
        const docRef = doc(db, "games", today);
        const gameDoc = await getDoc(docRef);

        if (gameDoc.exists()) {
          setGameData(gameDoc.data());
          setCurrentWordIndex(0);
        } else {
          setError("No game data available for today.");
        }
      } catch (err) {
        setError(`Failed to fetch game data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchGameData();
  }, []);

  // Fetch gradient colors for the current word
  useEffect(() => {
    const fetchGradientColors = async () => {
      if (gameData) {
        const today = new Date().toISOString().split("T")[0];
        const docRef = doc(db, "games", today);

        try {
          const gameDoc = await getDoc(docRef);
          if (gameDoc.exists()) {
            const words = gameDoc.data().words;
            const currentWord = words?.[currentWordIndex];
            const gradient = currentWord?.gradient || {};
            const colors = Object.values(gradient);
            setGradientColors(colors);
          } else {
            setGradientColors([]);
          }
        } catch (err) {
          setGradientColors([]);
        }
      }
    };

    fetchGradientColors();
  }, [gameData, currentWordIndex]);

  // Memoize blob styles
  const blobStyles = useMemo(() => {
    const colors = isGameStarted
      ? gradientColors
      : generateRandomColors(numberOfBlobs); // Random colors before game starts
    return [...Array(numberOfBlobs)].map(() => ({
      backgroundColor: colors[Math.floor(Math.random() * colors.length)],
      animationDelay: `${Math.random() * 5}s`,
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
    }));
  }, [gradientColors, isGameStarted]);

  // Set up word and game state when currentWordIndex or gameData changes
  useEffect(() => {
    if (gameData?.words?.[currentWordIndex]) {
      const currentWord = gameData.words[currentWordIndex].word;
      setRevealedWord(Array(currentWord.length).fill("_"));
      setPoints(currentWord.length * 2);
      setGuessedLetters({});
      setMessage("");
    }
  }, [gameData, currentWordIndex]);

  const handleKeyPress = (event) => {
    if (!isGameStarted || isGameOver || !/^[a-z]$/.test(event.key)) return;

    const key = event.key.toLowerCase();
    const currentWord = gameData.words[currentWordIndex].word.toLowerCase();

    if (guessedLetters[key]) {
      setMessage(`"${key.toUpperCase()}" already guessed.`);
      return;
    }

    let isCorrectGuess = false;
    const updatedWord = [...revealedWord];
    const updatedGuessedLetters = { ...guessedLetters };

    if (currentWord.includes(key)) {
      currentWord.split("").forEach((letter, index) => {
        if (letter === key) {
          updatedWord[index] = letter;
          isCorrectGuess = true;
        }
      });
      updatedGuessedLetters[key] = "correct";
    } else {
      updatedGuessedLetters[key] = "incorrect";
      setPoints((prev) => Math.max(prev - 1, 0)); // Ensure points don't go below 0
    }

    setRevealedWord(updatedWord);
    setGuessedLetters(updatedGuessedLetters);

    if (updatedWord.join("") === currentWord) {
      setScore((prev) => prev + points);
      moveToNextWord();
    } else if (points === 0 && !isCorrectGuess) {
      setMessage("No points left! Moving to next word.");
      moveToNextWord();
    }
  };

  const moveToNextWord = () => {
    if (currentWordIndex < gameData.words.length - 1) {
      setCurrentWordIndex((prev) => prev + 1);
    } else {
      setIsGameOver(true);
    }
  };

  const resetGame = async () => {
    setLoading(true);
    setIsGameStarted(false);
    setIsGameOver(false);
    setGameData(null);
    setGradientColors([]);
    setCurrentWordIndex(0);
    setRevealedWord([]);
    setPoints(0);
    setScore(0);
    setGuessedLetters({});
    setMessage("");

    try {
      const today = new Date().toISOString().split("T")[0];
      const docRef = doc(db, "games", today);
      const gameDoc = await getDoc(docRef);

      if (gameDoc.exists()) {
        setGameData(gameDoc.data());
      } else {
        setError("No game data available for today.");
      }
    } catch (err) {
      setError(`Failed to fetch game data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [isGameStarted, isGameOver, guessedLetters, revealedWord, points]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="game-wrapper">
      {/* Lava lamp blobs spanning the entire wrapper */}
      <div className="lava-lamp">
        {blobStyles.map((style, index) => (
          <div key={index} className="blob" style={style}></div>
        ))}
      </div>

      <div className="modal-overlay">
        <div className="modal-content">
          {!isGameStarted && (
            <>
              <h1>LetterClub</h1>
              <p className="game-rules">
                Welcome to LetterClub! Here are the rules:
                <ul>
                  <li>Guess one letter at a time to reveal the hidden word.</li>
                  <li>Each incorrect guess deducts points.</li>
                  <li>If you guess the word, you score the remaining points!</li>
                  <li>Run out of points? Move to the next word.</li>
                  <li>Try to score as high as possible before the game ends!</li>
                </ul>
              </p>
              <button onClick={() => setIsGameStarted(true)}>READY</button>
            </>
          )}

          {isGameOver && (
            <>
              <h1>Game Over</h1>
              <p>Your total score: {score}</p>
              <button onClick={resetGame}>Play Again</button>
            </>
          )}

          {isGameStarted && !isGameOver && (
            <div className="game-container">
              <h1>LetterClub</h1>
              <div className="game-info">
                <h2>{gameData.words[currentWordIndex]?.category}</h2>
                <div className="word-box">{revealedWord.join(" ")}</div>
                <p>{message}</p>
                <p>Points: {points}</p>
                <p>Total Score: {score}</p>
              </div>
              <div className="letters">
                {"abcdefghijklmnopqrstuvwxyz".split("").map((letter) => (
                  <span key={letter} className={`letter ${guessedLetters[letter]}`}>
                    {letter.toUpperCase()}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Game;
